import { useState } from 'react';
import style from './ImageGeneratorForm.module.css';
import StyledButton from '../../../shared_components/StyledButton/StyledButton';

function ImageGeneratorForm({
	handleSubmit,
	textValue,
	handleChange,
	isInputEmpty,
	handleInputChange,
	isLoading,
}) {
	const [selectedOption, setSelectedOption] = useState('');
	const [assetName, setAssetName] = useState('');

	const handleSelectChange = (event) => {
		setSelectedOption(event.target.value);
	};

	const handleAssetNameChange = (event) => {
		setAssetName(event.target.value);
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();
		if (assetName.trim() === '') {
			alert('Asset Name is required!');
			return;
		}
		handleSubmit(event, textValue, selectedOption, assetName);
	};

	return (
		<div className={style.leftContainer}>
			<div className={style.leftblock}>
				<div className={style.styleBlock}>
					<h3>Style</h3>
					<p>
						Try out various styles to apply to your image and explore the
						possibilities
					</p>
					<div className={style.inputWithSelect}>
						<label htmlFor='styleSelect'>Select Style</label>
						<select
							id='styleSelect'
							value={selectedOption}
							onChange={handleSelectChange}
							className={style.select}>
							<option value=''>Select an option</option>
							<option value='Realistic'>Realistic</option>
							<option value='Sketch'>Sketch</option>
							<option value='Cartoon'>Cartoon</option>
							<option value='Flat Design'>Flat Design</option>
						</select>
					</div>
				</div>
				<div className={style.formPosition}>
					<label htmlFor='assetName'>Enter Asset Name</label>
					<input
						type='text'
						id='assetName'
						placeholder='Enter asset name'
						value={assetName}
						onChange={handleAssetNameChange}
						className={style.assetInput}
					/>
				</div>
				<div className={style.formPosition}>
					<h3>Prompt</h3>
					<p>
						What image do you want to see? Insert text in the box to generate
						image.
					</p>
					<div>
						<form onSubmit={handleFormSubmit} className={style.generator}>
							<textarea
								value={textValue}
								onChange={(event) => {
									handleChange(event);
									handleInputChange(event);
								}}
								placeholder='Type something...'
								rows={9}
								cols={50}
								className={style.textareaField}
								aria-label='Image prompt'
							/>
							<StyledButton
								disabled={isInputEmpty || isLoading || assetName.trim() === ''}
								className={`${style.styledButton} ${
									isInputEmpty || isLoading || assetName.trim() === ''
										? style.disabledButton
										: ''
								}`}>
								{isLoading ? 'Generating...' : 'Generate Image'}
							</StyledButton>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ImageGeneratorForm;
